import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

const userLogin = (userPool, username, password, callback) => {
    const authentificationData = {
        Username: username,
        Password: password
    };

    const authentificationDetails = new AuthenticationDetails(authentificationData);

    const userData = {
        Username: username,
        Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authentificationDetails, {
        onSuccess: (session) => {
            const token = session.getIdToken().getJwtToken();
            //return token
            callback(null, token);
        },
        onFailure: (error) => {
            callback(error, null);
        }
    });
}

export default userLogin;