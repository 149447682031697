import { CognitoUser } from "amazon-cognito-identity-js";

const userConfirmation = async (userPool, username, verificationCode) => {
    const userData = {
        Username: username,
        Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    await new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(verificationCode, true, (error, result) => {
            if(error){
                reject(error.message);
            } else {
                resolve(result);
            }
        });
    })
}

export default userConfirmation;