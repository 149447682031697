import { CognitoUserPool } from "amazon-cognito-identity-js";
import UserAuth from "./authentication/UserAuth";

class N7SDK {
    constructor(){

        const coUserPoolData = {
            UserPoolId: "us-east-1_if8PBHlTp",
            ClientId: "26s6deljof671lc55gpsbtdab7"
        }
        const coUserPool = new CognitoUserPool(coUserPoolData);

        this.CoUserAuth = new UserAuth(coUserPool);
    }
}

export default N7SDK;