import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const userRegistration = async (userPool, username, password, email) => {
    const attributes = [
        new CognitoUserAttribute({Name: 'email', Value: email})
    ];

    await new Promise((resolve, reject) => {
        userPool.signUp(username, password, attributes, null, (error, result) => {
            if (error){
                console.log(error.message);
                reject(error);
            } else {
                resolve(result);
            }
        });
    })
}

export default userRegistration;