import { CognitoIdToken } from "amazon-cognito-identity-js";
import userToken from "./userToken";

const checkSession = () => {
    const idToken = userToken();
    const cognitoIdToken = new CognitoIdToken({IdToken: idToken});
    const expirationTime = cognitoIdToken.payload.exp;
    const currentTime = Math.floor(new Date().getTime() / 1000);
    if(expirationTime < currentTime) {
        throw new Error("expired");
    } else {
        return "valid";
    }
}

export default checkSession;